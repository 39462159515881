const collator = new Intl.Collator(undefined, { ignorePunctuation: true });

export const localeCompare = (x: string, y: string) => collator.compare(x, y);

export const getLocaleMonthNames = () => {
    const result: string[] = [];

    for (let i = 0; i < 12; i++) {
        result.push(new Date(2000, i).toLocaleString('default', { month: 'long' }));
    }

    return result;
};
