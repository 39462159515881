/**
 * Returns a date string in the format YYYY-MM-DD from an ISO 8601 date string
 * Note: this function does not validate if the string is a valid ISO 8601 date string
 */
export function getDateStringFromISO8601String(dateString: string): string {
    return dateString.split('T')[0];
}

/**
 * Returns a time string in the format HH:MM from an ISO 8601 date string
 * Note: this function does not validate if the string is a valid ISO 8601 date string
 */
export function getTimeHHMMStringFromISO8601String(dateString: string): string {
    return dateString.split('T')[1].slice(0, 5);
}

/**
 * Returns an ISO 8601 date from a date string in the format YYYY-MM-DD and a time string in the format HH:MM
 * Note: this function does not validate if the strings are valid date and time strings
 */
export function getISO8601DateStringFromDateAndTimeHHMMString(dateString: string, timeString: string): string {
    return [dateString, 'T', timeString, ':00.000Z'].join('');
}

/**
 * Returns a Date object from a date string in the format YYYY-MM-DD in the local timezone
 * Note: this function does not validate if the date string is valid
 */
export function getDateFromDateString(dateString: string): Date {
    // eslint-disable-next-line @tritondigitaldev/unpredictable-number-conversion
    const dateParts = dateString.split('-').map((x) => parseInt(x, 10));

    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
}
