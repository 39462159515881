import { zMonth, zYear } from 'report/validation';

export type ReportPeriod = {
    year: number;
    month: number;
};

export const getReportPeriodFromStrings = ({
    year,
    month,
}: {
    year: string | undefined;
    month: string | undefined;
}): ReportPeriod | null => {
    const yearValidation = zYear.safeParse(year);
    const monthValidation = zMonth.safeParse(month);

    if (!yearValidation.success || !monthValidation.success) {
        return null;
    }

    return {
        year: yearValidation.data,
        month: monthValidation.data,
    };
};

export const getReportPeriodFromDate = (date: Date): ReportPeriod => {
    return {
        year: date.getUTCFullYear(),
        month: date.getUTCMonth() + 1,
    };
};

// turn a year and month (1-indexed) into a UTC date
// e.g. { year: 2000, month: 1 } will be midnight 1 January 2000 UTC
export const getDateUtcFromReportPeriod = ({ year, month }: ReportPeriod) => {
    // month is 1-indexed
    return new Date(Date.UTC(year, month - 1));
};

export const formatReportPeriodLabel = (period: ReportPeriod, locale: string) => {
    const localDate = new Date(period.year, period.month - 1);

    return Intl.DateTimeFormat(locale, {
        month: 'long',
        year: 'numeric',
    }).format(localDate);
};

export const reportPeriodsAreEqual = (a: ReportPeriod, b: ReportPeriod): boolean => {
    return a.year === b.year && a.month === b.month;
};
