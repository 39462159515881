import { z } from 'zod';
import { getPositiveInteger } from 'helpers/number';

export const zYear = z.string().transform((valueString, ctx) => {
    const value = getPositiveInteger(valueString);

    if (!value || value < 2000 || value >= 3000) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Value not a valid year, must be 2xxx',
        });

        return z.NEVER;
    }

    return value;
});

export const zMonth = z.string().transform((valueString, ctx) => {
    const value = getPositiveInteger(valueString);

    if (!value || value < 1 || value > 12) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Value not a valid month, must be 1 to 12',
        });

        return z.NEVER;
    }

    return value;
});

export const zISO8601String = z
    .string()
    .nullable()
    .refine(
        (value) => {
            if (!value) {
                return true;
            }

            // 2022-08-17T04:40:00.000Z
            return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value);
        },
        {
            message: 'Must be empty or ISO string',
        },
    );

export const zDate = z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), { message: 'Must be yyyy-mm-dd' });

export const zStringPositiveInteger = z.string().transform((valueString, ctx) => {
    const value = getPositiveInteger(valueString);

    if (value === null) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Value not a valid integer',
        });

        return z.NEVER;
    }

    return value;
});
