import { localeCompare } from 'helpers/locale';

export const compareString = (a: string, b: string): number => {
    return localeCompare(a, b);
};

export const compareNumber = (a: number, b: number): number => {
    if (a > b) {
        return 1;
    }

    if (a < b) {
        return -1;
    }

    return 0;
};
